@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

:root {
  --color-gold: #c49a6c;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  font-family: 'Roboto';
}

.top_section {
  z-index: 90;
  position: fixed;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  top: 0px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 10%);
  background-color: #000;
  border: 0px;
  border-radius: 0px;
  transition: background-color 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
}

.navigation_row {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 55px;
}

.logo {
  position: relative;
}

.logo_shadow {
  position: absolute;
  border-radius: 50%;
  background: #000;
  width: 140px;
  height: 98px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 10%);
}

.logo a {
  position: relative;
  top: 6px;
  left: 9px;
}

.nav_container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
}

.nav_menu_main {
  display: flex;
}

.nav_menu_element {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.nav_menu_element li {
  display: block;
  margin: 0;
  padding: 0;
  line-height: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu_item_type {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  line-height: 20px;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  transition: 0.2s ease-in;
}

.menu_item_type:hover {
  color: #fff;
  font-size: 1.2rem;
  transition: 0.2s ease-in;
}

.nav_menu_media {
  display: flex;
  justify-content: center;
  margin: auto 0;
  width: 88px;
}

.nav_menu_media a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.4rem;
  margin: 0 10px;
  transition: 0.2s ease-in;
}

.nav_menu_media a:hover {
  font-size: 1.6rem;
  transition: 0.2s ease-in;
}

@media (max-width: 600px) {
  .nav_container {
    transition: 0.3s ease-in;
    position: absolute;
    left: 80px;
    top: 10px;
    font-size: 8px;
  }

  .nav_menu_media {
    display: none;
  }

  .menu_item_type {
    letter-spacing: 1px;
  }
}

.parallax_video {
  position: relative;
  width: 100%;
}
.jarallax {
  object-fit: cover;
  width: 100%;
}

.jarallax:after {
  content: '';
  display: block;
  padding-top: 0%;
}

.jarallax > .video-table {
  object-fit: contain;
  font-family: 'object-fit: contain;';
  width: 100%;
  height: 300px;
  z-index: -1;
}

.table-cell {
  height: 50vh;
}

.info_panel {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  margin: 40px auto;
}

.info_panel_adresa {
  flex: 1 1;
  min-width: 250px;
}

.info_panel_adresa p {
  line-height: 8px;
}

.info_panel_maps {
  flex: 1 1;
}

.maps {
  min-width: 350px;
}

.parallax1 {
  position: relative;
  height: 500px;
  background-attachment: fixed;
  background-position: 50% calc(-200px + 1vmin);
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax2 {
  height: 240px;
  top: 400px;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.gap100,
.gap200,
.gap300 {
  background-color: transparent;
}

.gap100 {
  height: 100px;
}

.gap200 {
  height: 200px;
}

.gap200 {
  height: 300px;
}

.text_center {
  position: relative;
  width: 100%;
  padding: 10px;
}

.text1 {
  margin: 0 auto;
  padding: 30px 0 0px 0;
  font-size: calc(2rem + 1vmin);
  text-transform: uppercase;
  text-align: center;
}
.text2 {
  margin: 0 auto;
  font-size: calc(1rem + 1vmin);
  text-align: center;
  width: 80%;
  padding-bottom: 40px;
}
.text3 {
  margin: 0 auto;
  font-size: calc(1rem + 1vmin);
  text-align: center;
}

.divider {
  text-align: center;
  padding-top: 32px;
  height: 40px;
  display: flex;
}

.divider_separator {
  margin: 0 auto;
}

.divider_icon {
  position: relative;
  display: flex;
  width: 200px;
}

.divider_icon::before {
  display: block;
  content: '';
  border-bottom: 0;
  flex-grow: 1;
  border-top: 1px solid #333;
  width: 200px;
}

.divider_icon::after {
  display: block;
  content: '';
  border-bottom: 0;
  flex-grow: 1;
  border-top: 1px solid #333;
  padding-top: 30px;
  width: 200px;
}

.divider_icon svg {
  position: relative;
  top: -26px;
  left: -10px;
  width: 220px;
}

.btn_container {
  width: 100%;
  display: flex;
}

.btn_jelovnik {
  cursor: pointer;
  display: inline-block;
  width: 160px;
  background-color: #333;
  color: white;
  padding: 1rem;
  font-size: 16px;
  border: 0;
  border-radius: 5px;
  margin: 10px auto 40px auto;
  transition: 0.3s ease-in;
  text-align: center;
}

.btn_jelovnik:hover {
  background-color: #555;
  transition: 0.3s ease-in;
}

.btn_jelovnik:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  transition: 0.1s ease-in;
}

.mreze {
  padding-top: 0px;
  background-color: #222;
  color: rgba(255, 255, 255, 0.8);
}

.main_menu_media {
  z-index: 99;
  margin: 20px auto;
  font-size: 3rem;
  display: flex;
  justify-content: center;
}

.main_menu_media a {
  margin: 10px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.main_menu_media i {
  cursor: pointer;
  transition: 0.2s ease-in;
}

.main_menu_media i:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.2s ease-in;
  cursor: pointer;
}

.gold {
  color: var(--color-gold);
  cursor: default;
  padding-top: 0px;
}

.main_logo {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.footer {
  display: flex;
  background-color: black;
  color: rgba(255, 255, 255, 0.8);
  font-size: (1rem + 1vmin);
  height: 40px;
  justify-content: center;
  padding-top: 12px;
  margin-top: 10px;
}

.footer a {
  color: rgba(155, 155, 255, 0.8);
}

.footer .left {
  padding-left: 20px;
  flex: 1 1;
}

.footer .right {
  padding-right: 20px;
}

.MenuPage {
  display: flex;
  width: 100vw;
  height: calc(100vw * 2.5);
  overflow-x: hidden;
  flex-direction: column;
  background: #eee;
}

.Page {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

