.parallax_video {
  position: relative;
  width: 100%;
}
.jarallax {
  object-fit: cover;
  width: 100%;
}

.jarallax:after {
  content: '';
  display: block;
  padding-top: 0%;
}

.jarallax > .video-table {
  object-fit: contain;
  font-family: 'object-fit: contain;';
  width: 100%;
  height: 300px;
  z-index: -1;
}

.table-cell {
  height: 50vh;
}
