.top_section {
  z-index: 90;
  position: fixed;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  top: 0px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 10%);
  background-color: #000;
  border: 0px;
  border-radius: 0px;
  transition: background-color 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
}

.navigation_row {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 55px;
}

.logo {
  position: relative;
}

.logo_shadow {
  position: absolute;
  border-radius: 50%;
  background: #000;
  width: 140px;
  height: 98px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 10%);
}

.logo a {
  position: relative;
  top: 6px;
  left: 9px;
}

.nav_container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
}

.nav_menu_main {
  display: flex;
}

.nav_menu_element {
  display: flex;
  flex-wrap: nowrap;

  -webkit-box-pack: center;
  justify-content: center;
}

.nav_menu_element li {
  display: block;
  margin: 0;
  padding: 0;
  line-height: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menu_item_type {
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  line-height: 20px;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  transition: 0.2s ease-in;
}

.menu_item_type:hover {
  color: #fff;
  font-size: 1.2rem;
  transition: 0.2s ease-in;
}

.nav_menu_media {
  display: flex;
  justify-content: center;
  margin: auto 0;
  width: 88px;
}

.nav_menu_media a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.4rem;
  margin: 0 10px;
  transition: 0.2s ease-in;
}

.nav_menu_media a:hover {
  font-size: 1.6rem;
  transition: 0.2s ease-in;
}

@media (max-width: 600px) {
  .nav_container {
    transition: 0.3s ease-in;
    position: absolute;
    left: 80px;
    top: 10px;
    font-size: 8px;
  }

  .nav_menu_media {
    display: none;
  }

  .menu_item_type {
    letter-spacing: 1px;
  }
}
