.info_panel {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  margin: 40px auto;
}

.info_panel_adresa {
  flex: 1;
  min-width: 250px;
}

.info_panel_adresa p {
  line-height: 8px;
}

.info_panel_maps {
  flex: 1;
}

.maps {
  min-width: 350px;
}
