.parallax1 {
  position: relative;
  height: 500px;
  background-attachment: fixed;
  background-position: 50% calc(-200px + 1vmin);
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax2 {
  height: 240px;
  top: 400px;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.gap100,
.gap200,
.gap300 {
  background-color: transparent;
}

.gap100 {
  height: 100px;
}

.gap200 {
  height: 200px;
}

.gap200 {
  height: 300px;
}

.text_center {
  position: relative;
  width: 100%;
  padding: 10px;
}

.text1 {
  margin: 0 auto;
  padding: 30px 0 0px 0;
  font-size: calc(2rem + 1vmin);
  text-transform: uppercase;
  text-align: center;
}
.text2 {
  margin: 0 auto;
  font-size: calc(1rem + 1vmin);
  text-align: center;
  width: 80%;
  padding-bottom: 40px;
}
.text3 {
  margin: 0 auto;
  font-size: calc(1rem + 1vmin);
  text-align: center;
}

.divider {
  text-align: center;
  padding-top: 32px;
  height: 40px;
  display: flex;
}

.divider_separator {
  margin: 0 auto;
}

.divider_icon {
  position: relative;
  display: flex;
  width: 200px;
}

.divider_icon::before {
  display: block;
  content: '';
  border-bottom: 0;
  flex-grow: 1;
  border-top: 1px solid #333;
  width: 200px;
}

.divider_icon::after {
  display: block;
  content: '';
  border-bottom: 0;
  flex-grow: 1;
  border-top: 1px solid #333;
  padding-top: 30px;
  width: 200px;
}

.divider_icon svg {
  position: relative;
  top: -26px;
  left: -10px;
  width: 220px;
}

.btn_container {
  width: 100%;
  display: flex;
}

.btn_jelovnik {
  cursor: pointer;
  display: inline-block;
  width: 160px;
  background-color: #333;
  color: white;
  padding: 1rem;
  font-size: 16px;
  border: 0;
  border-radius: 5px;
  margin: 10px auto 40px auto;
  transition: 0.3s ease-in;
  text-align: center;
}

.btn_jelovnik:hover {
  background-color: #555;
  transition: 0.3s ease-in;
}

.btn_jelovnik:active {
  transform: scale(0.98);
  transition: 0.1s ease-in;
}

.mreze {
  padding-top: 0px;
  background-color: #222;
  color: rgba(255, 255, 255, 0.8);
}

.main_menu_media {
  z-index: 99;
  margin: 20px auto;
  font-size: 3rem;
  display: flex;
  justify-content: center;
}

.main_menu_media a {
  margin: 10px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.main_menu_media i {
  cursor: pointer;
  transition: 0.2s ease-in;
}

.main_menu_media i:hover {
  transform: scale(1.1);
  transition: 0.2s ease-in;
  cursor: pointer;
}

.gold {
  color: var(--color-gold);
  cursor: default;
  padding-top: 0px;
}

.main_logo {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.footer {
  display: flex;
  background-color: black;
  color: rgba(255, 255, 255, 0.8);
  font-size: (1rem + 1vmin);
  height: 40px;
  justify-content: center;
  padding-top: 12px;
  margin-top: 10px;
}

.footer a {
  color: rgba(155, 155, 255, 0.8);
}

.footer .left {
  padding-left: 20px;
  flex: 1;
}

.footer .right {
  padding-right: 20px;
}
