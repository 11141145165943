.MenuPage {
  display: flex;
  width: 100vw;
  height: calc(100vw * 2.5);
  overflow-x: hidden;
  flex-direction: column;
  background: #eee;
}

.Page {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
