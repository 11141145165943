@import url(https://fonts.googleapis.com/css?family=Roboto);

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

:root {
  --color-gold: #c49a6c;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  font-family: 'Roboto';
}
